import React from 'react';
import { graphql } from 'gatsby';
import Testimonial from '../components/testimonial';
import Hero from '../components/hero';
import Screenshot from '../components/screenshot';
import Logos from '../components/logos';
import Layout from '../components/layout';
import Feature from '../components/feature';
import FeatureList from '../components/feature-list';
import CallToAction from '../components/call-to-action';
import { Discussion, Disclosure, Annotation } from '../components/graphics';
import { useTranslation, Link, Trans } from 'gatsby-plugin-react-i18next';

export default function Landing() {
  const { t } = useTranslation();

  return (
    <Layout
      title={t('Home')}
      description={t(
        'Shared intelligence for innovation. Amplified uses AI to organize global patent and scientific information right next to yours.'
      )}
    >
      <Hero />
      <Screenshot />
      <Logos />
      <Feature
        gray
        side="left"
        header={t('Be one with your data')}
        subheader={t('Search less, find more')}
        body={t(
          "Our AI read over 140m patents so you don't have to. Focus on your work without having to think through complex queries and read distracting noise."
        )}
        graphic={<Disclosure />}
        cta={
          <div className="text-gray_4.5 mt-2">
            <Trans>
              <Link to="/how-it-works" className="text-utility_red hover:underline">
                Learn more
              </Link>{' '}
              about how Amplified uses deep learning to model the world of
              patent knowledge
            </Trans>
          </div>
        }
      />
      <Testimonial />
      <Feature
        gray
        side="right"
        header={t('Connect the dots')}
        subheader={t("Today's work fuels tomorrow")}
        body={t(
          'An enjoyable, modern research workflow that makes referencing past work and documenting today quick and easy.'
        )}
        graphic={<Annotation />}
      />
      <Feature
        side="left"
        header={t('Collaborate and share')}
        subheader={t('Connect people and knowledge')}
        body={t(
          'Skip the meetings, emails, and mess of different files. Share full context instantly instead. All the information. you need is kept together so you can collaborate more effectively.'
        )}
        graphic={<Discussion />}
      />
      <FeatureList />
      <CallToAction />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
