import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

function Graphic() {
  return (
    <StaticImage
      src="../images/cycle.png"
      className="relative mx-auto w-full"
      imgClassName="w-full"
      placeholder="tracedSVG"
      alt="How it works"
    />
  );
}

export default function Hero() {
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState({ email: '' });

  function handleChange(event, field) {
    setForm({ ...form, [field]: event.target.value });
  }

  return (
    <main className="mx-auto max-w-7xl px-4 sm:mt-16 sm:px-6 lg:mt-24">
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-5 lg:text-left pt-10">
          <h1>
            <span className="mt-1 block text-4xl font-display tracking-tight text-chocolate sm:text-5xl xl:text-5xl">
              <span className="block">
                <Trans>Shared intelligence</Trans>
              </span>
              <span className="block">
                <Trans>for innovation</Trans>
              </span>
            </span>
          </h1>
          <p className="mt-3 text-base text-chocolate sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
            <Trans>
              Amplified organizes global patent and scientific information right
              next to yours
            </Trans>
          </p>
          <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
            <form
              data-netlify="true"
              netlify-honeypot="bot-field"
              name={`get-a-demo-${i18n.language}`}
              action="/success"
              method="POST"
              className="mt-3 sm:flex"
            >
              <input
                type="hidden"
                name="form-name"
                value={`get-a-demo-${i18n.language}`}
              />
              <input type="hidden" name="bot-field" />
              <label htmlFor="email-1" className="sr-only">
                <Trans>Email</Trans>
              </label>
              <input
                type="text"
                name="email"
                id="email-1"
                className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
                placeholder={t('Your work email')}
                value={form.email}
                onChange={(event) => handleChange(event, 'email')}
              />
              <button
                type="submit"
                className="mt-3 w-full px-6 py-3 text-base font-medium font-display rounded-md text-white bg-pastel_red hover:bg-pastel_red_120 focus:outline-none focus:ring-2 focus:ring-bright_gold sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                disabled={Object.values(form).every((val) => !val)}
              >
                <Trans>Get a demo</Trans>
              </button>
            </form>
          </div>
        </div>
        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-7 lg:flex lg:items-center">
          <Graphic />
        </div>
      </div>
    </main>
  );
}
