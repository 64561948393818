import React from 'react';
import { GoldToppedHeader } from './molecules';
import { CheckIcon } from '@heroicons/react/outline';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

export default function FeatureList({}) {
  const { t } = useTranslation();

  const features = [
    {
      name: t('Unlimited team members'),
      description: t(
        "Add as many viewers as you want. See team member's usage and manage admin, billing, and invite permissions."
      ),
    },
    {
      name: t('Share, discuss, and annotate'),
      description: t(
        'Share projects to collaborate and discuss. Add annotations to patents to keep track of work and build a valuable knowledge base.'
      ),
    },
    {
      name: t('Security and privacy controls'),
      description: t(
        'Share projects with your entire team or only specific people. Control view, edit, and sharing permissions.'
      ),
    },
    {
      name: t('Download and upload patents'),
      description: t(
        "You can manually add patents one-at-time or in bulk to projects. Easily download top search results and export work you've done."
      ),
    },
    {
      name: t('Intuitive and powerful AI ranking'),
      description: t(
        'Powerful, flexible, and transparent. Choose from two ranking modes to quickly find relevant results without queries.'
      ),
    },
    {
      name: t('Robust search and filter features'),
      description: t(
        'You can search and highlight keywords, add wildcards or boolean logic, and filter on just about anything.'
      ),
    },
    {
      name: t('Project workflows'),
      description: t(
        'Guided workflows for core activities like invention disclosures, invalidity, and monitors.'
      ),
    },
    {
      name: t('Over 140m patents updated weekly'),
      description: t(
        'Global patent data inlcuding English machine translations for complete coverage. Family, citation, and legal status data too.'
      ),
    },
  ];

  return (
    <div className="bg-gray_0.5">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <GoldToppedHeader
            small
            header={t('All-in-one-platform')}
            topper={t('Everything you need')}
          />
          <p className="mt-4 text-lg text-gray_4">
            <Trans>
              Search, share, and collaborate on global patent data. Keep track
              of ideas, invention disclosures, and patent activity. Build an
              internal knowledge database.
            </Trans>
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-utility_green"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-chocolate">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray_4">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
