import React, { useState } from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

export default function CallToAction({}) {
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState({ email: '' });

  function handleChange(event, field) {
    setForm({ ...form, [field]: event.target.value });
  }

  return (
    <div className="bg-indigo-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-chocolate font-display md:text-4xl">
          <span className="block">
            <Trans>Ready to get started?</Trans>
          </span>
          <span className="block text-bright_gold_120 font-display">
            <Trans>Get in touch for a demo</Trans>
          </span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <form
            data-netlify="true"
            netlify-honeypot="bot-field"
            name={`get-a-demo-${i18n.language}-2`}
            action="/success"
            method="POST"
            className="mt-3 w-full sm:w-auto sm:flex"
          >
            <input
              type="hidden"
              name="form-name"
              value={`get-a-demo-${i18n.language}-2`}
            />
            <input type="hidden" name="bot-field" />
            <label htmlFor="email-2" className="sr-only">
              <Trans>Email</Trans>
            </label>
            <input
              type="text"
              name="email"
              id="email-2"
              className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
              placeholder={t('Your work email')}
              value={form.email}
              onChange={(event) => handleChange(event, 'email')}
            />
            <button
              type="submit"
              className="mt-3 w-full px-6 py-3 text-base font-medium font-display rounded-md text-white bg-pastel_red hover:bg-pastel_red_120 focus:outline-none focus:ring-2 focus:ring-bright_gold sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
              disabled={Object.values(form).every((val) => !val)}
            >
              <Trans>Get a demo</Trans>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
