import * as React from 'react';
import Soundhound from '../images/svg/soundhound.svg';

export default function Testimonial() {
  return (
    <section className="py-12 bg-white overflow-hidden md:py-20 lg:py-16">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative">
          <Soundhound className="mx-auto h-8" alt="Soundhound" />
          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-chocolate">
              <p>
                &ldquo;Pretty darn impressive. Within 2 minutes I was getting
                results right on target. In fact, the interface is addictive
                like a well-designed game.&rdquo;
              </p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:space-x-3 md:items-center">
                  <div className="text-base font-medium text-gray_3">
                    Jonah Probell
                  </div>
                  <div className="text-base font-medium text-gray_2">
                    IP Strategist, Soundhound
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
}
