import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export function Annotation() {
  return (
    <StaticImage
      src="../images/annotation.png"
      alt="Annotation"
      placeholder="tracedSVG"
    />
  );
}

export function Disclosure() {
  return (
    <StaticImage
      src="../images/disclosure.png"
      alt="Invention disclosure"
      placeholder="tracedSVG"
    />
  );
}

export function Discussion() {
  return (
    <StaticImage
      src="../images/discussion.png"
      alt="Discussion"
      placeholder="tracedSVG"
    />
  );
}
