import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export default function Logos() {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-8">
      <h3 className="text-chocolate text-center text-xl">
        <Trans>Trusted by leaders in innovation</Trans>
      </h3>
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-8 lg:grid-cols-8">
          <StaticImage
            src="../images/logos/central-glass.png"
            className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
            placeholder="tracedSVG"
            alt={t('Central Glass')}
          />
          <StaticImage
            src="../images/logos/three-d.png"
            className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
            placeholder="tracedSVG"
            alt={t('3D Printing Corporation')}
          />
          <StaticImage
            src="../images/logos/ricoh.png"
            className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
            placeholder="tracedSVG"
            alt={t('Ricoh')}
          />
          <StaticImage
            src="../images/logos/kracie.png"
            className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
            placeholder="tracedSVG"
            alt={t('Kracie')}
          />
          <StaticImage
            src="../images/logos/otsuka.png"
            className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
            placeholder="tracedSVG"
            alt={t('Otsuka Pharma')}
          />
          <StaticImage
            src="../images/logos/asahikasei.png"
            className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
            placeholder="tracedSVG"
            alt={t('Asahi Kasei')}
          />
          <StaticImage
            src="../images/logos/burkert.png"
            className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
            placeholder="tracedSVG"
            alt={t('Bürkert Fluid Control Systems')}
          />
          <StaticImage
            src="../images/logos/cybozu.png"
            className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
            placeholder="tracedSVG"
            alt={t('Cybozu')}
          />
        </div>
      </div>
    </div>
  );
}
