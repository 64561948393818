import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { GoldToppedHeader } from './molecules';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export default function Screenshot() {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 pt-16">
        <div className="text-center">
          <GoldToppedHeader
            header={t('Discover a new kind of productivity and insight')}
            topper={t('AI-Powered')}
          />
          <p className="my-3 max-w-md mx-auto text-base text-chocolate sm:text-lg md:mt-5 md:text-xl md:max-w-4xl">
            <Trans>
              Effortlessly research, document, and share technical intelligence
              with your team on Amplified
            </Trans>
          </p>
          <StaticImage
            src="../images/screenshot.png"
            className="rounded-lg mt-2"
            placeholder="blurred"
            alt={t('App screenshot')}
          />
        </div>
      </div>
    </div>
  );
}
