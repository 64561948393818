import React from 'react';
import { GoldToppedHeader } from './molecules';

function Graphic({ graphic }) {
  return (
    <div className="mt-12 sm:mt-16 lg:mt-0">
      <div className="w-3/4 lg:w-full mx-auto lg:mx-0 lg:relative lg:h-full">
        {graphic}
      </div>
    </div>
  );
}

function Text({ header, subheader, body, cta }) {
  return (
    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
      <div>
        <div className="mt-6">
          <GoldToppedHeader header={header} topper={subheader} small />
          <p className="mt-4 text-lg text-gray_4">{body}</p>
          {cta}
        </div>
      </div>
    </div>
  );
}

export default function Feature({
  gray,
  header,
  subheader,
  body,
  cta,
  side,
  graphic,
}) {
  return (
    <div
      className={`relative ${
        gray ? 'bg-gray_0.5' : 'bg-white'
      } px-8 py-8 overflow-hidden`}
    >
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          {side === 'left' && <Text {...{ header, subheader, body, cta }} />}
          <Graphic graphic={graphic} />
          {side === 'right' && <Text {...{ header, subheader, body, cta }} />}
        </div>
      </div>
    </div>
  );
}
